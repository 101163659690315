import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import logo from './assets/logo.svg'

function App() {
  return (
    <Box
      sx={{
        backgroundColor: '#1AA3BA',
        minHeight: '100vh',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Stack
      spacing={2}
      >
        <Box
          sx={{
          display:'flex',
          justifyContent:'center'
        }}
        >
        <img src={logo} alt='' />
      </Box>
      <Box
        sx={{
          fontWeight: 700,
          fontSize: '25px',
          display:'flex',
          justifyContent:'center'
      }}
        >SITE UNDER CONSTRUCTION
        </Box>
        </Stack>
      </Box>
  );
}

export default App;
